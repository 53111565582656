var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fetchingFile
        ? _c(
            "div",
            [
              _c("v-skeleton-loader", { attrs: { type: "article@2" } }),
              _c("v-skeleton-loader", {
                staticClass: "ml-4",
                attrs: { type: "paragraph" },
              }),
            ],
            1
          )
        : _c("viewer", {
            ref: "toastuiEditor",
            staticStyle: { "overflow-x": "auto" },
            attrs: {
              options: _vm.defaultOptions,
              initialValue: _vm.editorText,
              height: "500px",
            },
            on: { load: _vm.loaded },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }